<template>
  <div class="securities">
    <img-div
      class-name=""
      height="400"
      :img-url="require('@/assets/business/securities/top_banner.png')"
    >
      <div class="container">
        <div class="p1 mt54">
          {{ $t("business.securities.topBannerTitle") }}
        </div>
        <div class="desc1 mt26">
          {{ $t("business.securities.topBannerDesc1") }}
          <br />
          <br />
        </div>
        <div class="desc1">
          {{ $t("business.securities.topBannerDesc2") }}
        </div>
      </div>
    </img-div>
    <div class="container">
      <div class="about-url flex flex-jsb">
        <!-- <a :href="$t('business.securities.dealLinkUrl')" target="_blank"> -->
        <div class="url-item flex flex-v flex-vc" @click="showDeal = !showDeal">
          <!-- <el-image
              :src="require('@/assets/business/securities/dealonline.png')"
              fit="scale-down"
              class="deal-icon"
            ></el-image> -->
          <img
            :src="require('@/assets/business/securities/dealonline.png')"
            class="deal-icon"
          />
          <!-- <img src="" alt=""> -->
          <span class="listItemTitle">{{
            $t("business.securities.dealOnline")
          }}</span>
          <div class="itemALink flex flex-vc">
            {{ $t("business.securities.dealOnlineLink")
            }}<img
              class="a-right"
              src="@/assets/business/securities/a-right.png"
              alt=""
            />
          </div>
        </div>
        <!-- </a> -->
        <div class="url-item flex flex-v flex-vc" @click="show = !show">
          <!-- <el-image
              :src="require('@/assets/business/securities/dealonline.png')"
              fit="scale-down"
              class="deal-icon"
            ></el-image> -->
          <img
            :src="require('@/assets/business/securities/openonline.png')"
            class="deal-icon"
          />
          <!-- <img src="" alt=""> -->
          <span class="listItemTitle">{{
            $t("business.securities.openOnline")
          }}</span>
          <div class="itemALink flex flex-vc">
            {{ $t("business.securities.dealFeesLink")
            }}<img
              class="a-right"
              src="@/assets/business/securities/a-right.png"
              alt=""
            />
          </div>
        </div>
        <div
          class="url-item flex flex-v flex-vc"
          @click="$router.push('/business/transCost')"
        >
          <!-- <el-image
            :src="require('@/assets/business/securities/dealfees.png')"
            fit="scale-down"
            class="deal-icon"
          ></el-image> -->
          <img
            :src="require('@/assets/business/securities/dealfees.png')"
            class="deal-icon"
          />
          <span class="listItemTitle">{{
            $t("business.securities.dealfees")
          }}</span>
          <a href="javascript:;" class="itemALink flex flex-vc"
            >{{ $t("business.securities.dealFeesLink")
            }}<img
              class="a-right"
              src="@/assets/business/securities/a-right.png"
              alt=""
          /></a>
        </div>
        <div class="url-item flex flex-v flex-vc" @click="toZatar">
          <!-- <el-image
            :src="require('@/assets/business/securities/dealfees.png')"
            fit="scale-down"
            class="deal-icon"
          ></el-image> -->
          <img
            :src="require('@/assets/business/securities/zarta.png')"
            class="deal-icon"
          />
          <span class="listItemTitle">{{
            $t("business.securities.zarta")
          }}</span>
          <a href="javascript:;" class="itemALink flex flex-vc"
            >{{ $t("business.securities.dealFeesLink")
            }}<img
              class="a-right"
              src="@/assets/business/securities/a-right.png"
              alt=""
          /></a>
        </div>
      </div>
    </div>
    <img-div
      class-name=""
      height="300"
      :img-url="require('@/assets/business/securities/bt_banner.png')"
    >
      <div class="container">
        <div class="p2 mt102">
          {{ $t("business.securities.btBannerTitle") }}
        </div>
        <div class="desc1 mt28 text-center init-indent">
          {{ $t("business.securities.btBannerDesc") }}
        </div>
      </div>
    </img-div>
    <div class="container advantage">
      <div class="itemTitle">
        {{ $t("business.securities.advantageTitle") }}
      </div>
      <div class="flex flex-jsb flex-hw">
        <div
          class="advantage-item flex flex-v flex-vc"
          v-for="(item, index) in advantageList"
          :key="index"
        >
          <el-image
            :src="item.imgUrl"
            fit="scale-down"
            class="deal-icon"
          ></el-image>
          <span class="listItemTitle">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <model-box v-model="show" />
    <model-deal v-model="showDeal" />
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
import ModelBox from "@/components/business/ModelBox.vue";
import ModelDeal from "@/components/business/ModelDeal.vue";
export default {
  components: {
    ImgDiv,
    ModelBox,
    ModelDeal,
  },
  data() {
    return {
      show: false,
      showDeal: false,
    };
  },
  computed: {
    advantageList() {
      return this.$t("business.securities.advantageList") || [];
    },
  },
  created() {},
  methods: {
    toZatar() {
      window.open("https://m.qkintl.com.hk/zarta.html");
    },
  },
};
</script>
<style lang="scss" scoped>
.securities {
  background-color: var(--whiteColor);
  .about-url {
    padding: 50px 131px 67px;
  }
  .deal-icon {
    width: 84px;
    image-rendering: -webkit-optimize-contrast;
  }
  .a-right {
    width: 14px;
  }
  .url-item {
    cursor: pointer;
  }
  .advantage {
    padding-bottom: 105px;
    .advantage-item {
      width: 380px;
      padding: 73px 0 23px;
    }
  }
}
</style>
